<template>
  <div class="discount-detail">
    <!-- 导航栏 -->
    <van-nav-bar :title="title" fixed placeholder :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('home.withdrawMoney') }}</span>
      </template>
    </van-nav-bar>
    <!-- 内容 -->
    <div class="detail-content" v-html="content">
      {{ content }}
    </div>
  </div>
</template>

<script>
import { getSaleInfo } from '@/api/sale.js'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      id: 0,
      title: '',
      content:
        `<meta name="viewport" content="width=device-width,initial-scale=1"><div style="margin:30px;font-size:16px;text-align:center">${this.$t('common.loading')}</div>`
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.id = this.$route.query.id
    this.title = this.$route.query.title
    this.getSaleInfoHandler()
  },
  methods: {
    async getSaleInfoHandler() {
      
      const {
        data: { data, ret }
      } = await getSaleInfo({
        id: this.id
      })
      if (ret === 1) {
        this.content = data.content
      } else {
        // language-ch 数据加载失败
        this.content =
          `<meta name="viewport" content="width=device-width,initial-scale=1"><div style="margin:100px;font-size:16px;text-align:center">${this.$t('discounts.dataDownloadFailed')}</div>`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.discount-detail {
  height: 100%;
  background-color: #fff;

  /deep/ .van-nav-bar {
    .van-nav-bar__content {
      background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .detail-content {
    padding: 25px;
    background-color: #fff;
  }
}
</style>
